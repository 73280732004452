<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.customerCreate')"
      :title="$t('messages.customerNew')"
      @submitPressed="submitPressed"
    />
    <customer-form :userObject="user" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CustomerForm from './components/CustomerForm.vue'

export default {
  components: {
    TitleBar,
    CustomerForm
  },
  data() {
    return {
      action: null,
      user: {
        givenName: '',
        familyName: '',
        role: 'ROLE_CLIENT',
        telephone: '',
        email: '',
        description: '',
        onOff: false,
        address: {
          streetAddress: '',
          city: '',
          postalCode: '',
          flatNumber: null,
          floorNumber: null,
        },
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
